#feedbackButton {
    text-decoration: none;
    display: inline-block;
  }

#main-logo {
    max-width: 7%;
}

.smallerImage {
  max-width: 70%;
  padding: 25px;
}

@media only screen and (max-width: 1000px) {
      .smallerImage {
        max-width: 100%;
        padding: 25px;
      }
    }